import "../../style.css";
import "../css/index.css";
import "./polyfills.js";
import { createInertiaApp } from "@inertiajs/vue3";
import { createApp, h } from "vue";

createInertiaApp({
    id: "app",
    resolve: (name) => import(`./vue/pages/${name}.vue`),
    async setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .mount(el);
    },
});
